import React from "react";
import Dropzone from 'react-dropzone';
import axios from "axios";
import PropTypes from 'prop-types';
import Upload from "./Upload";

class UploadsArea extends React.Component {
  static propTypes = {
    endPointRoot: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
  };

  state = {
    uploads: [],
    over: false,
  };

  uploadDroppedFile = (e) => {
    const { uploads } = this.state;
    this.setState({ over: false });
    const files = e;
    files.forEach((file) => {
      file.finished = false;
      file.id = Math.random() * 100000;
      uploads.push(file);
      this.uploadFile(file);
    });
    this.setState({ uploads });
  };

  uploadSelectedFile = (e) => {
    const { uploads } = this.state;
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      file.finished = false;
      uploads.push(file);
      this.uploadFile(file);
    });
    this.setState({ uploads });
  };

  incrementUploadCounter = () => {
    const { endPointRoot, token, clientId } = this.props;
    const url = `${endPointRoot}/clients/${clientId}/incrementUploadFiles/`;
    const config = { headers: { token } };

    axios.put(url, {}, config)
      .catch((error) => {
        console.error(error);
      });
  };

  uploadFile = (file) => {
    const { endPointRoot, token, clientId } = this.props;
    const { uploads } = this.state;
    const url = `${endPointRoot}/clients/${clientId}/attachments/`;
    const data = {
      fileName: file.name,
      uploadedBy: "client",
    };
    const config = { headers: { token } };
    axios.post(url, data, config)
      .then((response) => {
        const uploadURL = response.data.data.url;
        axios.put(uploadURL, file, {
          headers: { 'Content-Type': file.type },
        })
          .then((r) => {
            this.incrementUploadCounter();
            const foundFile = uploads.find((f) => {
              return f.name === file.name;
            });
            if (foundFile) {
              foundFile.finished = true;
              this.setState({ uploads });
            }
          })
          .catch((error) => {
            console.warn(error);
          });
      }).catch((error) => {
        console.warn(error);
      });
  };

  render() {
    const { uploads, over } = this.state;
    const { clientName } =this.props;
    return (
      <div style={{ marginTop: "3em" }}>
        <section className="uza-contact-area" style={{ paddingTop: "80px" }}>
          <div className="container">
            <div className="row justify-content-between">
              <div className="contact-heading" style={{ width: "100%" }}>
                <h3 className="title" style={{ marginTop: 0 }}>Client File Uploads</h3>
                <h5 style={{ marginBottom: "1em" }}>
                  Upload documents for&nbsp;
                  <span style={{ color: "#1583e9" }}>{clientName}</span>
                  .
                </h5>
                <Dropzone
                  onDrop={this.uploadDroppedFile}
                  onDragEnter={() => { this.setState({ over: true }); }}
                  onDragLeave={() => { this.setState({ over: false }); }}
                >
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <section>
                        <div
                          {...getRootProps()}
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                            position: "relative",
                          }}
                        >
                          <input {...getInputProps()} />

                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              color: "#666",
                              transition: "opacity 0.2s",
                              opacity: over ? "0.3" : "0",
                            }}
                          >
                            <h4>Drop file here to upload</h4>
                          </div>

                          <div
                            style={{
                              opacity: over ? 0.5 : 1,
                              borderRadius: "25px",
                              border: "3px solid rgba(0,0,0,0.1)",
                              padding: "1em",
                              paddingBottom: "0.5em",
                              transition: "opacity 0.2s",
                              minHeight: "100px",
                            }}
                          >
                            {uploads.map((upload) => {
                              return <Upload key={upload.name} uploadInProgress={!upload.finished} upload={upload} />;
                            })}
                            {!over && uploads.length < 1 && (
                              <h4 style={{ color: "#aaa", textAlign: "center", marginTop: "20px" }}>Drag files here to upload</h4>
                            )}
                          </div>

                        </div>
                      </section>
                    );
                  }}
                </Dropzone>
                <label htmlFor="file-upload" className="btn uza-btn btn-3 mt-15">
                  Add File
                </label>
                <input id="file-upload" type="file" multiple hidden onChange={this.uploadSelectedFile} />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default UploadsArea;
