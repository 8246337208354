import {
  faFileWord,
  faFilePowerpoint,
  faFileExcel,
  faFileArchive,
  faFileVideo,
  faFileAudio,
  faFilePdf,
  faFileImage,
} from '@fortawesome/free-regular-svg-icons';
// for finding the coresponding icon to a file extension

/* eslint-disable */
const types = [
  { icon: faFileWord, extensions: ["doc", "docx", "docm", "abw", "odt"] }, // documents
  { icon: faFilePowerpoint, extensions: ["ppt", "pptx", "odp"] }, // presentations
  { icon: faFileExcel, extensions: ["xlsx", "xls", "xlsm", "xlsb", "xlsx", "xlt", "ods"] }, // spreadsheets
  { icon: faFileArchive, extensions: ["zip", "rar", "7z", "arc", "bz", "bz2", "jar", "tar", "gz"] }, // archive
  { icon: faFileVideo, extensions: ["3gp", "3g2", "avi", "mpeg", "ogv", "webm", "wmv", "mov", "mkv", "flv", "vob", "gifv", "qt", "mp4", "m4p", "mpeg", "m4v"] }, // video
  { icon: faFileAudio, extensions: ["aa", "aac", "flac", "m4a", "m4b", "m4p", "mp3", "ogg", "oga", "wav", "wma"] }, // audio
  { icon: faFilePdf, extensions: ["pdf"] }, // pdf
  { icon: faFileImage, extensions: ["jpeg", "jpg", "tiff", "gif", "bmp", "png", "svg"] }, // images
];

export default types
