import React from "react";
import * as PropTypes from "prop-types";

class ExpiredArea extends React.Component {
  propTypes = {
    clientName: PropTypes.string.isRequired,
  };

  render() {
    const { clientName } = this.props;
    return (
      <div style={{ marginTop: "3em" }}>
        <section className="uza-contact-area" style={{ paddingTop: "80px" }}>
          <div className="container" style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ margin: "auto", marginBottom: "2em" }}>
              <img alt="Expired link" width="106px" src="/images/hourglass.svg" />
            </div>
            <div style={{ margin: "auto", maxWidth: "550px", textAlign: "center" }}>
              <span style={{ color: "#828282", fontSize: "24px", lineHeight: "28px" }}>
                Hi
                <span style={{ color: "#c4c4c4" }}>
                  {clientName}
                </span>
                , this link appears to be expired.
                Please contact your advisor to request a new link.
              </span>
            </div>
            <div
              style={{
                margin: "auto",
                color: "#c4c4c4",
                fontSize: "16px",
                lineHeight: "19px",
                textAlign: "center",
                maxWidth: "550px",
                marginTop: "1em",
              }}
            >
              If you belive this is an error, please contact us at the number provided below or&nbsp;
              <a
                href="mailto:support@myhubly.com"
                style={{
                  fontWeight: "normal",
                  color: "#1583e9",
                  fontSize: "16px",
                  textDecoration: "underline",
                }}
              >
                let us know
              </a>
              &nbsp;by email.
            </div>

          </div>
        </section>
      </div>
    );
  }
}

export default ExpiredArea;
