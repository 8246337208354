const local = {
  gateway: { URL: 'http://localhost:8000' },
};

const dev = {
  gateway: { URL: 'https://5szxw6c7j6.execute-api.us-east-1.amazonaws.com/dev' },
};

const staging = {
  gateway: { URL: 'https://t3ntfed9fi.execute-api.us-east-1.amazonaws.com/staging' },
};

const pr = {
  gateway: { URL: 'https://evtl6tj8c9.execute-api.us-east-1.amazonaws.com/pr' },
};

const prod = {
  gateway: { URL: 'https://3pnif5ldk3.execute-api.us-east-1.amazonaws.com/prod' },
};

const customBuild = {
  gateway: { URL: process.env.REACT_APP_SERVICE_ENDPOINT },
};

let config = dev;

switch (process.env.REACT_APP_STAGE) {
  case 'prod':
    config = prod;
    break;
  case 'pr':
    config = pr;
    break;
  case 'staging':
    config = staging;
    break;
  case 'dev':
    config = dev;
    break;
  case 'custom-build':
    config = customBuild;
    break;
  case 'local':
    config = local;
    break;
  default:
    config = dev;
    break;
}

export default { ...config };
