import React from "react";
import Spinner from "./Spinner";

class LoadingArea extends React.Component {
  render() {
    return (
      <div style={{ marginTop: "3em" }}>
        <section className="uza-contact-area" style={{ paddingTop: "80px" }}>
          <div className="container">
            <div className="row justify-content-between">
              <div className="contact-heading" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div style={{ margin: "auto", display: "flex", height: "50px", alignItems: "center", marginTop: "4em" }}>
                  <div>
                    <h3 style={{ display: "inline", marginRight: "0.5em", color: "#828282" }}>
                      Fetching secure upload link...
                    </h3>
                  </div>

                  <Spinner active />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default LoadingArea;
