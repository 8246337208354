import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

export class Spinner extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
  };

  render() {
    const { active } = this.props;
    return (
      <ClipLoader
        sizeUnit="px"
        size={30}
        color="#1583e9"
        loading={active}
      />
    );
  }
}


export default Spinner;
