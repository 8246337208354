import React from 'react';
import Moment from "moment";
import filesize from "filesize";
import * as PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Spinner from "./Spinner";
import extensionIcons from "../extensionIcons";

class Upload extends React.Component {
  static propTypes = {
    upload: PropTypes.object.isRequired,
    uploadInProgress: PropTypes.bool.isRequired,
  };

  getIcon = (name) => {
    const index = name.lastIndexOf(".");
    if (index < 0) return "file outline";
    const extension = name.substring(index + 1);
    const fileType = extensionIcons.find((type) => {
      return type.extensions.find((ext) => {
        return ext === extension;
      });
    });
    if (!fileType) return faFile;
    return fileType.icon;
  };


  render() {
    const { upload, uploadInProgress } = this.props;
    return (
      <div style={{ display: "flex", marginBottom: "0.75em" }}>
        <div
          style={{
            width: "50px",
            height: "55px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {uploadInProgress
            ? (
              <div>
                <Spinner active />
              </div>
            )
            : <FontAwesomeIcon icon={this.getIcon(upload.name)} style={{ fontSize: "20pt", color: "#828282" }} />
            }
        </div>
        <div style={{ width: "calc(100% - 70px)" }}>
          <div
            style={{
              width: "100%",
              fontSize: "12pt",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={upload.name}
          >
            {upload.name}
            { !uploadInProgress ? (
              <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#1583e9", marginLeft: "0.5em" }} />
            ) : null
            }
          </div>
          <p
            style={{
              color: "darkgrey",
              fontSize: "10pt",
              lineHeight: "1.2em",
            }}
          >
            {!uploadInProgress ? (
              <React.Fragment>
                Uploaded at
                {Moment(upload.uploadTime)
                  .format(" h:mma, MMM Do YYYY")}
              </React.Fragment>
            ) : <span>Uploading...</span>}
            <br />
            {filesize(upload.size)}
          </p>
        </div>
      </div>
    );
  }
}


export default Upload;
