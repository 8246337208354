import React from "react";
import { hot } from "react-hot-loader";
import axios from "axios";
import UploadsArea from "./components/UploadsArea";
import NotFoundArea from "./components/NotFoundArea";
import ExpiredArea from "./components/ExpiredArea";
import LoadingArea from "./components/LoadingArea";
import config from './config';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gettingLink: true,
      notExist: false,
      expired: false,
      endPointRoot: this.getEndPointURL(),
    };
  }

  componentDidMount() {
    this.verifyUploadLink();
  }

  getEndPointURL = () => {
    return config.gateway.URL;
  };

  verifyUploadLink = () => {
    const pageURL = window.location.href;
    const { endPointRoot } = this.state;
    const regex = /.*\/uploads\/([a-z]*)\/.*/g; // group 1 will be the token .../uploads/{token}
    const output = regex.exec(pageURL);
    if (!output || output.length < 2) {
      this.setState({ notExist: true, gettingLink: false });
      return;
    }
    const token = output[1];
    const url = `${endPointRoot}/clientUploadTokens/validate/`;
    axios.get(url, { headers: { token } })
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType && contentType.indexOf("application/json") !== -1) {
          this.setState({
            clientId: response.data.clientId,
            token: response.data.token,
            clientName: response.data.clientName,
          });
        } else {
          this.setState({ notExist: true }); // Never existed state
        }
      })
      .catch((error) => {
        const { status } = error.response;
        if (status === 401) {
          this.setState({ expired: true }); // Expired state
        } else {
          this.setState({ notExist: true }); // Never existed state
        }
      })
      .finally(() => {
        this.setState({ gettingLink: false });
      });
  };

  render() {
    const { gettingLink, notExist, expired, endPointRoot, clientId, token, clientName } = this.state;

    if (gettingLink) return <LoadingArea />;
    if (expired) return <ExpiredArea clientName={clientName} />;
    if (notExist) return <NotFoundArea />;

    return <UploadsArea endPointRoot={endPointRoot} clientId={clientId} token={token} clientName={clientName} />;
  }
}

export default process.env.NODE_ENV === "development" ? hot(module)(App) : App;
